import "./App.css";
import React, { Component } from "react";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Header from "./components/header";
import Footer from "./components/footer";

import ImageLicenceP1 from "./assets/licence-p1.jpg";
import ImageLicenceP2 from "./assets/licence-p2.jpg";
import ImageLicenceP3 from "./assets/licence-p3.jpg";
import ImageLicenceP4 from "./assets/licence-p4.jpg";
import ImageLicenceP5 from "./assets/licence-p5.jpg";

function App() {
  return (
    <div className="App">
      <Header />
      <div className="content">
        <div className="divider" />
        <section
          style={{
            maxWidth: 700,
            margin: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Carousel
            autoPlay={true}
            infiniteLoop={true}
            showIndicators={true}
            showThumbs={false}
            showArrows={true}
            showStatus={false}
            interval={5000}
          >
            <div className="licence-item">
              <img src={ImageLicenceP1} />
            </div>
            <div className="licence-item">
              <img src={ImageLicenceP2} />
            </div>
            <div className="licence-item">
              <img src={ImageLicenceP3} />
            </div>
            <div className="licence-item">
              <img src={ImageLicenceP4} />
            </div>
            <div className="licence-item">
              <img src={ImageLicenceP5} />
            </div>
          </Carousel>
        </section>
        <div className="divider" />
      </div>
      <Footer />
    </div>
  );
}

export default App;
