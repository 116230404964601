import React from "react";
import ImageLogoWhite from "../assets/logo-horizontal-white.svg";
import IconWA from "../assets/wa.svg";
import IconInst from "../assets/inst.svg";
import { Link } from "react-router-dom";
export default function Header() {
  return (
    <header className="App-header">
      <Link to="/">
        <img src={ImageLogoWhite} height={80} />
      </Link>
      <div className="contacts">
        <div className="tel-social">
          <a href="tel:+77715684050">+7 (771) 568 4050</a>
          <a href="https://wa.me/77715684050" target="_blank">
            <img src={IconWA} width={40} />
          </a>
          <a href="https://www.instagram.com/perspective.kst" target="_blank">
            <img src={IconInst} width={40} />
          </a>
        </div>

        <div className="email">
          <a href="mailto:perspective.kst@gmail.com" style={{ color: "white" }}>
            perspective.kst@gmail.com
          </a>
        </div>
      </div>
    </header>
  );
}
