import React from "react";
import IconWA from "../assets/wa.svg";
import IconInst from "../assets/inst.svg";

export default function Footer() {
  return (
    <footer>
      <div className="footer-data">
        <h3>Наш Адрес: </h3>
        <h4>Байтурсынова 72, каб. 406А</h4>
        <div className="contacts">
          <a href="tel:+77715684050">+7 (771) 568 4050</a>
          <a href="https://wa.me/77715684050" target="_blank">
            <img src={IconWA} width={40} />
          </a>
          <a href="https://www.instagram.com/perspective.kst" target="_blank">
            <img src={IconInst} width={40} />
          </a>
        </div>
        <div>
          <a href="mailto:perspective.kst@gmail.com" style={{ color: "white" }}>
            perspective.kst@gmail.com
          </a>
        </div>
      </div>
      <div className="footer-map">
        <div style={{ position: "relative", overflow: "hidden" }}>
          <iframe
            src="https://yandex.kz/map-widget/v1/?ll=63.634948%2C53.216859&mode=whatshere&whatshere%5Bpoint%5D=63.634959%2C53.216838&whatshere%5Bzoom%5D=17&z=17.36"
            width="100%"
            height="295px"
            frameborder="0"
            allowfullscreen="true"
            style={{ position: "relative" }}
          ></iframe>
        </div>
      </div>
    </footer>
  );
}
