import "./App.css";
import React, { Component } from "react";

import ImageBack1 from "./assets/back1.jpg";
import ImageBack2 from "./assets/back2.jpg";
import ImageBack3 from "./assets/back3.jpg";
import ImageBack4 from "./assets/back4.jpg";
import ImageBack5 from "./assets/back5.jpg";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Header from "./components/header";
import Footer from "./components/footer";
import ImageLicenceP1Small from "./assets/licence-p1_small.jpg";

import { Link } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Header />

      <div className="content">
        <div className="divider" />
        <section className="carousel">
          <Carousel
            autoPlay={true}
            infiniteLoop={true}
            showIndicators={false}
            showThumbs={false}
            showArrows={false}
            showStatus={false}
            interval={5000}
          >
            <div className="car-item">
              <img src={ImageBack1} />
              <p className="legend2">
                Проектное агентство "Перспектива" предлагает полный спектр услуг
                по разработке проектно-сметной документации от получения
                исходных данных до оформления акта ввода в эксплуатацию.
              </p>
            </div>
            <div className="car-item">
              <img src={ImageBack2} />
              <p className="legend2">
                Мы специализируемся на эскизном, техническом и рабочем
                проектировании, гарантируя индивидуальный подход к каждому
                клиенту.
              </p>
            </div>
            <div className="car-item">
              <img src={ImageBack5} />
              <p className="legend2">
                Наша команда специалистов обеспечит высокое качество выполнения
                проектов и соблюдение всех актуальных норм и стандартов.
              </p>
            </div>
          </Carousel>
        </section>
        <div className="divider" />
        <section
          style={{ marginBottom: 20, paddingLeft: 20, paddingRight: 20 }}
        >
          <h2>Наши услуги</h2>
          <div style={{ marginBottom: 50 }}>
            <ul style={{ paddingLeft: 15 }} className="services-list">
              <li>Эскизный проект (новое строительство)</li>
              <li>
                Технический проект (перепланировка, реконструкция, капитальный
                ремонт)
              </li>
              <li>
                Рабочий проект (генплан, архитектурно-строительная часть,
                инженерные сети, ПОС, экологический раздел, смета)
              </li>
              <li>Самостоятельный и комиссионный акты ввода в эксплуатацию</li>
              <li>Авторский надзор</li>
              <li>Бесплатная консультация по вопросам проектирования</li>
            </ul>
          </div>

          <div style={{ marginBottom: 50 }}>
            <button
              className="cta-btn"
              onClick={() => {
                window.location.href = "https://wa.me/77715684050";
              }}
            >
              Cвяжитесь с нами в WhatsApp
            </button>
          </div>

          {/* <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              marginTop: 20,
            }}
          >
            <h2
              style={{
                textAlign: "center",
              }}
            >
              Наши Проекты
            </h2>
            <img src={BugsImage} width="300" />
          </div> */}
        </section>
        <div className="divider" />
        <section>
          <div style={{ marginTop: 50 }}>
            <Link to="/licence">
              <div className="licence">
                <img src={ImageLicenceP1Small} width={300} />
                <h4>Подробнее</h4>
              </div>
            </Link>
          </div>
        </section>
        <div className="divider" />
      </div>
      <Footer />
    </div>
  );
}

export default App;
